export class FeedbackRequest {
  firstName: string = '';
  lastName: string = '';
  email: string = '';
  comments: string = '';
  dataUsage: string[] = [];
  organization: string = '';
  role: string = '';
  satisfactionLevel: string = '';
  url: string = '';
}
