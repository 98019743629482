const urlParameterKeys = {
  homePage: {
    waterRightsDisplayOptions: 'wrd',
    waterRightsFilters: 'wr',
    map: 'map',
    mapStyle: 'ms',
  },
};

//depricated: do not use these.  Only for migrating data.
export const depricatedUrlParameterKeys = {
  isWaterRightsNldiFilterActive: 'nldiActive',
};

export default urlParameterKeys;
